import React, {Component} from 'react';

import {Image, Layer, Line, Stage, Text} from 'react-konva';
import useImage from 'use-image';
import {Badge, Modal, OverlayTrigger, Popover} from "react-bootstrap";

const IMAGE_URL = '/doll.png';

const CANVAS_WIDTH = (350);
const CANVAS_HEIGHT = (470);

const IMAGE_WIDTH = (295/2) * 1.2;
const IMAGE_HEIGHT = (781/2) * 1.2;

const BODY_TOP_POS = 10;
const BODY_MIDDLE_POS = (IMAGE_HEIGHT/2) - 40;
const BODY_BOTTOM_POS = (IMAGE_HEIGHT/2) + 90;

const BODY_BOTTOM_MAIN_POS = IMAGE_HEIGHT - 40;

const BODY_TOP_ID = 1;
const BODY_MIDDLE_ID = 2;
const BODY_BOTTOM_ID = 3;
const BODY_TOP_MAIN_ID = 4;
const BODY_BOTTOM_MAIN_ID = 5;

// @todo: temporary fix, need adjustments in v2.0, client need to update symptom positions on body

const HEAD_LABELS = ['Grove gelaatstrekken', 'Gebitsafwijkingen', 'Faciale dysmorfie', 'Gingivahypertrofie', 'Macrocefalie', 'Macroglossie', 'Microcefalie', 'Micrognathie'];
const BACK_LABELS = ['Hyperlordose', 'Kyfose', 'Pectus carinatum', 'Scoliose', 'Ruggenmergcompressie'];

const Body = () => {

    const [image] = useImage(IMAGE_URL, 'body');
    const imageRef = React.useRef();

    // when image is loaded we need to cache the image
    React.useEffect(() => {
        if (image) {
            imageRef.current.cache();
            imageRef.current.getLayer().batchDraw();
        }
    }, [image]);

    return (
        <Image
            ref={imageRef}
            x={0}
            y={0}
            width={IMAGE_WIDTH}
            height={IMAGE_HEIGHT}
            image={image}
        />
    );
};

class DollBody extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            symptom: {},
            currentHover: ""
        };
    }

    onClickItem = (item) =>{
        this.setState({
            symptom:item,
            showModal:true
        });
    };

    onMouseOver = (obj, item) =>{

        if(obj && item) {
            this.setState({
                currentHover: item.name
            });
        }

        document.body.style.cursor = "pointer";
    };

    onMouseOut = (obj, item) =>{

        if(obj && item) {
            this.setState({
                currentHover: ""
            });
        }

        document.body.style.cursor = "default";
    };

    /**
     * get position based on Id
     * @param part
     * @param scale
     * @returns {number}
     */
    getBodyPosition = (item) => {

        let bodyPosition = 0;

        if(HEAD_LABELS.indexOf(item.name).length > -1) {
           bodyPosition = BODY_TOP_POS;
           return bodyPosition;
        }

        if(BACK_LABELS.indexOf(item.name).length > -1) {
            bodyPosition = BODY_MIDDLE_POS;
            return bodyPosition;
        }

        switch(item.body_part) {

            case BODY_TOP_ID:
                return bodyPosition = BODY_TOP_POS;

            case BODY_MIDDLE_ID:
                return bodyPosition = BODY_MIDDLE_POS;

            case BODY_BOTTOM_ID:
                return bodyPosition = BODY_BOTTOM_POS;

            case BODY_BOTTOM_MAIN_ID:
                return bodyPosition = BODY_BOTTOM_MAIN_POS;
        }

        return bodyPosition;

    }

    renderBodyTopSymptoms(items, max) {

        return items.slice(0, max).map((item, i)=> {

            let position = this.getBodyPosition(item);


            return  <React.Fragment key={i}>
                <Text
                    x={ IMAGE_WIDTH - 10 }
                    y={ position + (25*i) + (i*5) - 5 }
                    text={item.name}
                    textDecoration={(this.state.currentHover == item.name) ? "underline" : ""}
                    fontSize={14}
                    width={156}
                    fontFamily={'\'Source Sans Pro\', sans-serif'}
                    onClick={()=>{this.onClickItem(item)}}
                    onTap={()=>{this.onClickItem(item)}}
                    onMouseOver={(obj)=>{this.onMouseOver(obj,item)}}
                    onMouseOut={(obj)=>{this.onMouseOut(obj,item)}}
                    align={'left'}/>
                <Line
                    points={[IMAGE_WIDTH/2 + (27), position + (7 * i), 140, position + (27 * i), 140, position + (27 * i)]}
                    lineJoin={'round'}
                    lineCap={'round'}
                    stroke="orange"
                    strokeWidth={2}
                />
            </React.Fragment>;
        });

    }
    renderBodyMiddleSymptoms(items, max) {
        return items.slice(0, max).sort(((a, b) => a.name.localeCompare(b.name))).map((item, i)=> {

            let position = this.getBodyPosition(item);

            return  <React.Fragment key={i}>
                <Text
                    x={IMAGE_WIDTH}
                    y={ position + (30*i) - 10 }
                    text={item.name}
                    textDecoration={(this.state.currentHover == item.name) ? "underline" : ""}
                    fontSize={14}
                    width={175}
                    height={30}
                    fontFamily={'\'Source Sans Pro\', sans-serif'}
                    onClick={()=>{this.onClickItem(item)}}
                    onTap={()=>{this.onClickItem(item)}}
                    onMouseOver={(obj)=>{this.onMouseOver(obj,item)}}
                    onMouseOut={(obj)=>{this.onMouseOut(obj,item)}}
                />
                <Line
                    points={[IMAGE_WIDTH/2+ (27), (position - 50) + (15 * i), 160, position + (27 * i)]}
                    lineJoin={'round'}
                    lineCap={'round'}
                    stroke="orange"
                    strokeWidth={2}
                />
            </React.Fragment>;
        });
    }
    renderBodyBottomSymptoms(items, max, drawline) {

        return items.slice(0,max).map((item, i)=> {

            let position = this.getBodyPosition(item);

            return  <React.Fragment key={i}>
                { drawline &&
                <Line
                    points={[IMAGE_WIDTH/2+ (27), position + (14 * i), 160, position + (27 * i)]}
                    lineJoin={'round'}
                    lineCap={'round'}
                    stroke="orange"
                    strokeWidth={2}
                /> }
                <Text x={IMAGE_WIDTH }
                      y={ position + (25*i) + (i*5) - 5 }
                      width={160}
                      text={item.name} fontSize={14}
                      textDecoration={(this.state.currentHover == item.name) ? "underline" : ""}
                      fontFamily={'\'Source Sans Pro\', sans-serif'}
                      onClick={()=>{this.onClickItem(item)}}
                      onTap={()=>{this.onClickItem(item)}}
                      onMouseOver={(obj)=>{this.onMouseOver(obj,item)}}
                      onMouseOut={(obj)=>{this.onMouseOut(obj,item)}}
                />
            </React.Fragment>;
        });
    }
    render() {

        const {symptoms} = this.props;
        const {symptom, showModal} = this.state;

        const symptomsByDisease = symptoms.map((item)=>{

            let symptom = item;

            if(HEAD_LABELS.indexOf(item.name) > -1) {
                symptom.body_part = BODY_TOP_ID;
            }
            if(BACK_LABELS.indexOf(item.name) > -1) {
                symptom.body_part = BODY_MIDDLE_ID;
            }
            return symptom;

        });

        const body_top = symptomsByDisease.length >= 1 && symptomsByDisease.filter(item => item.body_part === BODY_TOP_ID);
        const body_middle = symptomsByDisease.length >= 1 && symptomsByDisease.filter(item => item.body_part === BODY_MIDDLE_ID);
        const body_bottom = symptomsByDisease.length >= 1 && symptomsByDisease.filter(item => item.body_part === BODY_BOTTOM_ID);

        const body_main = symptomsByDisease.length >= 1 && symptomsByDisease.filter(item => item.body_part === BODY_BOTTOM_MAIN_ID);

        return (
            <React.Fragment>
                <Modal
                    centered
                    show={showModal}
                    onHide={()=>{
                        this.setState({showModal:false});
                    }}
                    dialogClassName="modal-90w"
                    aria-labelledby="symptom-modal"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="symptom-modal">
                          {symptom.name} <Badge variant="light">{symptom.name_visual}</Badge>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{symptom.synonymous}</p>
                    </Modal.Body>
                </Modal>
                <OverlayTrigger trigger={['click', 'hover', 'focus']} placement="top" overlay={
                    <Popover id="popover-basic">
                        <Popover.Content>
                            De genoemde symptomen dienen als overzicht en komen niet uitsluitend voor op de aangewezen positie. Tevens kan het ziektebeeld meer symptomen omvatten dan hier weergeven.
                        </Popover.Content>
                    </Popover>
                }>
                    <a href="#"  data-toggle="popover" className="searchSymptoms-results__infoBtn"/>
                </OverlayTrigger>
                <Stage onContentTouchmove={(e)=>{e.preventDefault();}} width={CANVAS_WIDTH} height={CANVAS_HEIGHT} className={'disease-section__disease-intro-doll'}>
                    <Layer>
                        <Body />
                    </Layer>
                    <Layer>
                        { body_top.length >= 1 && this.renderBodyTopSymptoms(body_top, 5) }
                    </Layer>
                    <Layer>
                        { body_middle.length >= 1 && this.renderBodyMiddleSymptoms(body_middle, 8)}
                    </Layer>
                    <Layer>
                        { body_bottom.length > 0 && this.renderBodyBottomSymptoms(body_bottom, 7, true) }
                    </Layer>
                    <Layer>
                        { body_main.length >= 1 && this.renderBodyBottomSymptoms(body_main, 5, BODY_BOTTOM_MAIN_POS, false) }
                    </Layer>
                </Stage>
            </React.Fragment>
        )
    }
}
export default DollBody;
