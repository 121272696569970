import React, {Component} from "react";
import {Alert, Button, Modal, Spinner} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Portal from "../Portal";
import Name from "../FormComponents/Name";
import Email from "../FormComponents/Email";
import Subject from "../FormComponents/Subject";
import Message from "../FormComponents/Message";


class Share extends Component {

    constructor(props){
        super(props);
        this.state = {
            form_error:false,
            form_sending:false,
            form_send: false,
            name_to: "",
            email_to: "",
            name_from: "",
            email_from: "",
            page_title: "",
            page_url: ""
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleSubmit(event) {

        event.preventDefault();
        const {data} = this.props;
        const {name_to, name_from, email_to, email_from} = this.state;

        if(email_from == "" || email_to == "") {
            this.setState({
                form_error: true,
                form_sending: false
            });
            return false;
        }

        this.setState({
            form_sending:true
        });

        fetch(`${process.env.GATSBY_API_URL}/share-page`, {
            method: 'POST',
            body: JSON.stringify({
                "user": this.state,
                "data" : data
            })
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if(data.code == 200 || data.code == 202 ){
                this.setState({
                    form_send:true,
                    form_sending:false,
                });
            }
        });

    }
    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    render (){

        const {show, onHide, data} = this.props;
        const {email_to, email_from, name_to, name_from, form_send, form_sending, form_error} = this.state;

        // @todo: reuse formcomponents for validation

        return (
            <Portal>
            <div className="disease-share">
                <Modal
                    centered
                    show={show}
                    onHide={()=>{
                        setTimeout(()=>{
                            this.setState(
                                {
                                    form_send:false,
                                    name_from: "",
                                    name_to: "",
                                    email_to: "",
                                    data:null
                                }
                            );
                        }, 200)
                        onHide();
                    }}
                    dialogClassName="modal-90w"
                    aria-labelledby="diseases-share-modal"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="diseases-share-modal">
                            <p>Pagina delen</p>
                            <h3>{data.title}</h3>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="diseases-share-modal">
                        {form_send && <Alert variant="light"><h5>Bericht verzonden</h5><p>Het artikel is verzonden naar <strong>{email_to}</strong></p></Alert>}
                        {!form_send && <Form onSubmit={this.handleSubmit}>
                            {form_error &&
                            <Alert variant="warning"><h4>Probleem met verzenden</h4><p>Zo te zien zijn er sommige velden
                                niet correct ingevuld.</p></Alert>}
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Naam ontvanger</Form.Label>
                                <Form.Control name="name_to" placeholder="Naam ontvanger" value={name_to}
                                              onChange={this.handleChange}/>
                            </Form.Group>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Email van ontvanger</Form.Label>
                                <Form.Control name="email_to" type="email" placeholder="Email ontvanger"
                                              value={email_to} onChange={this.handleChange}/>
                                <Form.Text className="text-muted">
                                    Wij delen nooit het email adres met derde partijen.
                                </Form.Text>
                            </Form.Group>

                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Uw naam</Form.Label>
                                <Form.Control name="name_from" placeholder="Uw naam" value={name_from}
                                              onChange={this.handleChange}/>
                            </Form.Group>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Uw email</Form.Label>
                                <Form.Control name="email_from" type="email" placeholder="Uw email" value={email_from}
                                              onChange={this.handleChange}/>
                                <Form.Text className="text-muted">
                                    Wij delen nooit uw email met derde partijen.
                                </Form.Text>
                            </Form.Group>
                            <Button variant="primary" className="secondary-btn" type="submit">
                                {form_sending && <Spinner size="sm" animation="border"/>}
                                {!form_sending && <span>Delen</span>}
                            </Button>
                        </Form>
                        }
                    </Modal.Body>
                </Modal>
            </div>
            </Portal>
        )

    }

}

export default Share;