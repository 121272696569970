import React, { Component, createRef } from 'react';
import BaseLayout from '../components/Layout/BaseLayout';
import { graphql } from 'gatsby';
import parse from 'html-react-parser';
import GoogleMapReact from 'google-map-react';

import {Link} from 'gatsby';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import {Modal, Row, Col} from "react-bootstrap";

import Scrollspy from 'react-scrollspy';

import DollBody from "../components/DollBody";
import Scroll from "../components/Scroll";
import {db} from "../graphql/client";
import {gql} from "apollo-boost";
import Share from "../components/Share";
import {DISEASES_URL} from "../constants";
import StickComponent from "../components/StickComponent";


let numOfSections = [];
let refs = {};

const RenderMap = ({content}) => {

    const renderMarkers = (map, maps) => {
        let contentString;
        let infowindow = new maps.InfoWindow();

        let pins = JSON.parse(content.header[0].custom_data[0]);
        if(pins.expertiseCentra.length > 0) {

          for (let i = 0; i < pins.expertiseCentra.length; i++) {
            let formattedPhoneNumber = pins.expertiseCentra[i].telephoneNumber.replace(/\s/g,'');

            contentString = `
                <div style="display:flex;">
                    <div style="margin-right:10px; width: 50%"><p> ${pins.expertiseCentra[i].title} <br>${pins.expertiseCentra[i].address1} <br>${pins.expertiseCentra[i].address2} <br/> ${pins.expertiseCentra[i].address3} </p>
                    </div>
                    <div style="width: 50%">
                        <p><a href="tel:${formattedPhoneNumber}">Telefoon nr: <br><b>${pins.expertiseCentra[i].telephoneNumber}</b></a></p>
                        <p><a href="mailto:${pins.expertiseCentra[i].email}">Email: <br><b>${pins.expertiseCentra[i].email}</b></a></p>
                        <a href="${pins.expertiseCentra[i].website}" target="_blank" class="btn">Bezoek website</a>
                    </div>
                
                </div>`;
            let marker = new maps.Marker({
              position: {lat: pins.expertiseCentra[i].coordinates.lat, lng: pins.expertiseCentra[i].coordinates.lng},
              map,
              info: contentString,
              icon: {url:'/location.png', scaledSize: new maps.Size(25, 39.75)},
              title: pins.expertiseCentra[i].title,
            });
            marker.addListener('click', function() {
              infowindow.setContent(this.info);
              infowindow.open(map, marker);
            });
          }
        }
    }

    let customData = JSON.parse(content.header[0].custom_data[0]);
    return (
        <div className="disease-section__map">
        <Row>
            <Col sm={12} lg={5} className="first-cell">
                {customData.expertiseCentra.length > 1 && <h2>Expertisecentra</h2>}
                {customData.expertiseCentra.length < 2 && <h2>Expertisecentrum</h2>}

                {
                    parse(content.content)
                }
            </Col>
            <Col sm={12} lg={7}>
                <div className="map-container" style={{width:'100%', height:450, backgroundColor:'rgba(253, 153, 127, 0.1)'}}>
                    <GoogleMapReact
                        options={{
                            styles: [
                                { stylers: [{ 'saturation': -100 }, { 'gamma': 1.5 }] }
                            ]
                        }}
                        bootstrapURLKeys={{ key: process.env.GATSBY_GOOGLE_MAPS_API }}
                        defaultCenter={{lat: 52.3667, lng: 4.8945}}
                        center={{lat: 52.3667, lng: 4.8945}}
                        defaultZoom={7}
                        zoom={7}
                        draggable={true}
                        onGoogleApiLoaded={({map, maps}) => renderMarkers(map, maps)}
                        yesIWantToUseGoogleMapApiInternals={true}
                    />
                </div>
            </Col>
        </Row>
        </div>
    )
};

const RenderDesktopDetailedDisease = ({ sections, symptoms }) => {


    refs = sections.reduce((acc, value, x) => {
        acc[x] = createRef();
        return acc;
    }, {});


    return sections.map((child, x) => {

        let splitContent = [];
        // get second module of disease page
        // place doll body component
        if(x === 1 && (typeof document !== 'undefined')) {
            // split paragraph to place body doll element
            let span = document.createElement('div');
            span.innerHTML= '<div>' + child.content + '</div>';

            let children = span.querySelectorAll('*');

            for(let i = 0 ; i < children.length ; i++) {
                splitContent.push(children[i].outerHTML);
            }
        }


        return (
            <section ref={refs[x]} className={`disease-section ${child.header[0].body_classes}`} key={x} id={`section-${x}`}>
                {
                    {
                        'disease': (<React.Fragment>
                                    {(x == 1 && splitContent.length > 0) ? <div className={'disease-section__disease-intro'}><DollBody symptoms={symptoms}/>{parse(splitContent[0].toString())}</div>: parse(child.content)}
                            </React.Fragment>
                        ),
                        'map': ( <RenderMap content={child}/>),

                    }[child.template]
                }
            </section>
        )
    });
}


const RenderMobileDetailedDisease = ({ sections, symptoms, onChangeHander }) => {

    return (<Accordion allowZeroExpanded={true} allowMultipleExpanded={false} onChange={onChangeHander}>
        {
            sections.map((child, x) => {
                return (
                    <AccordionItem key={x} id={child.header[0].body_classes} data-panel={'panel-' + x} onClick={()=>{
                        currentPanelId = x;
                    }}>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                <span/>{child.title}
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            {
                                {
                                    'disease': ((x === 1) ? <React.Fragment>{parse(child.content)}<DollBody
                                        symptoms={symptoms}/></React.Fragment> : parse(child.content)),
                                    'map': (<RenderMap content={child}/>)
                                }[child.template]
                            }
                        </AccordionItemPanel>
                    </AccordionItem>
                );
            })
        }
    </Accordion>);
};

let isLocalStorage = null;
let currentPanelId = -1;

class Disease extends Component {

    constructor(props) {

        super(props);
        this.state = {
            show: false,
            showModal: false,
            showShareModal: false,
            isMobile: false,
            symptoms: [],
            symptom: {},
            currentPanelId:-1
        };
        this.handleClick = this.handleClick.bind(this);
        this.onChangeAccordion = this.onChangeAccordion.bind(this);


    }

    throttledHandleWindowResize = () => {
        this.setState({ isMobile: window.innerWidth < 991 });
    }

    componentDidMount() {

        window.addEventListener('resize', this.throttledHandleWindowResize);

        this.setState({
            show: true,
            isMobile: window.innerWidth < 991
        });

        // // create an array with all the sections for ScrollSpy component
        for (let i = 0; i < this.props.data.gravql.page.children.length; i++) {
            numOfSections = numOfSections.concat(`section-${i}`);
        };

        // get tags from session storage
        isLocalStorage = sessionStorage.getItem('tags');

        // get sup links for references
        const supLinks = document.querySelectorAll("sup a");
        for(let l = 0; l < supLinks.length; l++) {
            supLinks[l].addEventListener("click",(e)=>{

                e.preventDefault();

                const posNr = e.currentTarget.getAttribute("href");
                const elem = document.querySelector(`[data-name="${posNr.replace('#','')}"]`);

                const elemPos = elem ? elem.getBoundingClientRect().top + window.pageYOffset : 0;

                // show pulse effect
                elem.classList.add('pulse');
                setTimeout(()=>{
                    elem.classList.remove('pulse');
                }, 1500);

                window.scroll({ top: elemPos - 60, left: 0, behavior: 'smooth'});

            },false);
        }


        this.getSymptomsOfDisease();

        // get symptoms from section
        let symptoms = document.querySelectorAll("#section-2");

        // search for symptom links and place listener to call modal
        for(let s = 0; s < symptoms.length; s++) {

           let links = symptoms[s].querySelectorAll('a');

           for(let x = 0; x < links.length;x++) {
                   const btn = links[x];
                    btn.addEventListener("click", (e) => {
                        let innerText = btn.innerText.replace(/ *\([^)]*\) */g, "");
                        if(btn.getAttribute('data-link') === "lysosomale-stapelingsziekte/symptomen"){
                           e.preventDefault();
                           this.getSymptomData(innerText);
                        }
                   }, false);
            }
        }
    }

    componentWillUnmount() {
        if (typeof window !== 'undefined') {
            window.removeEventListener('resize', this.throttledHandleWindowResize);
        };
    }

    // scroll to the section on side-bar-menu click
    handleClick = (id) => {
        // detect support for the behavior property in ScrollOptions

        if (typeof window !== 'undefined' && typeof document !== 'undefined') {

            const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;

            const el = document.getElementById(refs[id].current.id);

            if (el == null) return;

            const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;

            window.scrollTo({
                top: yCoordinate - 150,
                behavior: 'smooth'
            });

            // IE SUPPORT FOR LACK OF SMOOTH SCROLL
            if(!supportsNativeSmoothScroll) {
                refs[id].current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                });
            }

        }

    };

    /**
     * get symptoms of current Disease and draw doll
     */
    getSymptomsOfDisease(node) {

        let data = this.getCustomData('symptoms');

        let query = "";
        for(let i = 0; i < data.symptoms.length; i++) {
            query +=  data.symptoms[i].id + ","
        }
        // DB query
        db.query({
            query: gql`
            {
              symptomsById(query:"${query}") {             
                  name
                  description
                  body_part
                  position
                  synonymous
                  name_visual
              }
            }
        `})
        .then((response) => {

            let data = response.data.symptomsById;

            this.setState({
                symptoms:data
            });
        })
        .catch(error => {
            console.log('getSymptomsOfDisease', error)
        });
    }

    /**
     * gets custom data for custom params, @todo: make function global
     * @param needle
     * @returns {any}
     */
    getCustomData(node) {

        const { data } = this.props;

        let customData = data.gravql.page.header[0].custom_data && data.gravql.page.header[0].custom_data;

        let obj = customData.filter((item)=>{
            let objName = Object.keys(JSON.parse(item))[0];
            return objName == node;
        });
        return (obj.length) ? JSON.parse(obj[0]) : null;
    }

    getSymptomData(query) {
        // DB query
        db.query({
            query: gql`
            {
              symptoms(query:"${query}") {
                  name
                  description
                  synonymous
              }
            }
        `})
            .then((response) => {

                let data = response.data.symptoms;
                this.setState({
                    symptom:(data.length === 1) ? data[0] : {name: query, synonymous: '-'},
                    showModal:true
                });
            })
            .catch(error => {
                console.log('getSymptomData', error)
            });
    }

    renderBackButton () {

        // on gatsby static build, window is unavailable, what causing an error.
        // check before rendering

        if('undefined' !== typeof window) {
            return <Link to={'#'} onClick={(e)=>{
                e.preventDefault();
                window.history.go(-1);
            }} className="back-btn">Terug</Link>;
        }

        return <div/>

    }

    onChangeAccordion(id) {

        let el = document.querySelector(`[data-panel="panel-${currentPanelId}"]`);

        if(el === null)return;
        if (typeof window !== 'undefined') {
            const y = (el.getBoundingClientRect().top + window.pageYOffset) - 90;
            window.scrollTo({top: y, behavior: "smooth"})
        }

    }

    render() {

        const { data, location } = this.props;
        const { symptom, showModal, symptoms, showShareModal, isMobile} = this.state;

        let disease = data.gravql.page.route.replace(DISEASES_URL+"/", "");
        let $downloadLink = `${process.env.GATSBY_API_URL}/download-pdf/ref:${disease}`;
        let $downloadLinkPrint = `${process.env.GATSBY_API_URL}/download-pdf/print:true/ref:${disease}`;

        return (
            <BaseLayout show={this.state.show} data={data.gravql} location={location} crumbLabel={data.gravql.page.title} showSubNav={true}>
                <Modal
                    centered
                    show={showModal}
                    onHide={()=>{
                        this.setState({showModal:false});
                    }}
                    dialogClassName="modal-90w"
                    aria-labelledby="diseases-modal"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="diseases-modal">
                            {(symptom !== null) ? symptom.name : ``}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                          {(symptom !== null) ? symptom.synonymous : ``}
                        </p>
                    </Modal.Body>
                </Modal>
                <Share
                    data={data.gravql.page}
                    show={showShareModal}
                    onHide={()=>{this.setState({showShareModal:false})}}
                />
                <section className="disease-main">
                    <div className="disease-main__buttons">
                        <div className="disease-main__buttons_start">
                        {isLocalStorage && this.renderBackButton()}
                        </div>
                        <div className="disease-main__buttons_end">

                            <a href={$downloadLink} target="_blank" rel="noopener noreferrer">
                                <i className="download"/>
                                <span>Download</span>
                            </a>
                            <a href={$downloadLinkPrint} target="_blank" rel="noopener noreferrer">
                                <i className="print" />
                                <span>Printen</span>
                            </a>
                            <a href="#" onClick={(e)=>{e.preventDefault(); this.setState({showShareModal:true});}} target="_blank" rel="noopener noreferrer">
                                <i className="share"/>
                                <span>Delen</span>
                            </a>

                        </div>
                    </div>
                    <Row className="content">
                        <Col sm={12} lg={3}>
                            <StickComponent
                                canBeSticky={true}
                                topOffset={78}
                                scrollOffset={184}>
                                <div className="side-menu">
                                    <Scrollspy items={numOfSections} currentClassName='is-current' offset={-150}>
                                    {data.gravql.page.children.map((child, x) => {
                                    return (
                                        <li key={x}>
                                            <i className={ child.header[0].body_classes } />
                                            <Scroll type={'id'} element={`section-${x}`} offset={-150}>
                                                <a href={`#section-${x}`}>{child.title}</a>
                                            </Scroll>
                                        </li>
                                    )
                                    })}
                                    </Scrollspy>
                                </div>
                            </StickComponent>
                        </Col>
                        <Col sm={12} lg={9} >
                            <h1 className="title">{data.gravql.page.title}</h1>
                            {isMobile ? <RenderMobileDetailedDisease sections={data.gravql.page.children} onChangeHander={this.onChangeAccordion} symptoms={symptoms}/> : <RenderDesktopDetailedDisease sections={data.gravql.page.children} symptoms={symptoms} />}
                        </Col>
                    </Row>

                </section>
            </BaseLayout>
        )
    }
}

export default Disease;

export const query = graphql`
  query ($slug:String!) {
    gravql {
        pages {
            title
            route
            visible
              breadcrumbs
            header {
                title
                body_classes
                 custom_data
            }
            children {
                title
                route
                header {
                    title
                    body_classes
                }
                children {
                    title
                    route
                }
            }
        }
        page(route: $slug) {
            title      
            content
            visible
            route
            breadcrumbs
            header {
                body_classes
                custom_data
            }
            children {
                title
                route  
                content  
                template 
                id
                header {
                    body_classes
                    custom_data
                    title
                }
                 children {
                    title
                    route
                }         
            }         
            metadata {
                name
                content
            }   
        }
    }
  }
`;
